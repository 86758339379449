<template>
  <div class="warp">
    <el-form :inline="true" size="mini">
      <el-form-item :label="isPlayer ? 'Player ID:' : 'Team ID:'">
        <el-input v-model="extraData.id" type="number" placeholder="请输入" clearable=""></el-input>
      </el-form-item>
      <el-form-item label="Sport:">
        <select-box
          :value.sync="extraData.sportId"
          search-key="name"
          label-key="sportsName"
          value-key="id"
          :url="STD_SEARCH_SPORT"
          :disabled="extraData.id ? true : false"
          :queryOrderParams="{ page: 1, size: 500 }"
        />
      </el-form-item>
      <el-form-item label="Region:" v-if="!isPlayer">
        <select-box
          :value.sync="extraData.regionId"
          search-key="name"
          label-key="name"
          value-key="id"
          :url="STD_SEARCH_REGION"
          :disabled="extraData.id ? true : false"
          :queryOrderParams="{ page: 1, size: 500, sportId: extraData.sportId, deleteFlag: 1 }"
        />
      </el-form-item>
      <el-form-item label="Tournament:">
        <select-box
          :url="STD_SEARCH_TOURNAMENT"
          :queryOrderParams="{ page: 1, size: 100, sportId: extraData.sportId, regionId: extraData.regionId  }"
          :value.sync="extraData.tournamentId"
          search-key="name"
          label-key="name"
          value-key="id"
        />
      </el-form-item>
      <el-form-item :label="isPlayer ? 'Player' : 'Team:'">
        <input-autocomplete
          :extraData.sync="extraData.name"
          :search-key="isPlayer ? 'STD_SEARCH_PLAYER' : 'STD_SEARCH_TEAM'"
          value-key="name"
          label-key="id"
          query-key="name"
          :disabled="extraData.id ? true : false"
          :queryOrderParams="{ page: 1, size: 100, sportId: extraData.sportId,tournamentId: extraData.tournamentId,regionId: extraData.regionId }"
        />
      </el-form-item>
      <el-form-item label="Validity" >
        <el-select v-model="extraData.isVisible" placeholder="" clearable="">
          <el-option label="Yes" :value="1"></el-option>
          <el-option label="No" :value="0"></el-option>
        </el-select>
      </el-form-item>
       <el-form-item label="Club Team ID" v-if="isPlayer">
        <el-input v-model="extraData.clubTeamId1" type="number" placeholder="请输入" clearable=""></el-input>
      </el-form-item>
      <el-form-item label="Club Team" v-if="isPlayer">
            <select-box
                :url="STD_SEARCH_TEAM"
                ref="team1"
                width="237"
                :queryOrderParams="{
                  page: 1,
                  size: 100,
                  isVisible: 1,
                  tournamentId: extraData.tournamentId,
                  sportId: extraData.sportId
                }"
                :value.sync="extraData.clubTeamId"
                search-key="name"
                label-key="name"
                value-key="id"
              />
      </el-form-item>
      <el-form-item label="Country Team ID" v-if="isPlayer">
        <el-input v-model="extraData.regionTeamId1" type="number" placeholder="请输入" clearable=""></el-input>
      </el-form-item>
      <el-form-item label="Country Team" v-if="isPlayer">
          <select-box
              :url="STD_SEARCH_TEAM"
              ref="team2"
              width="237"
              :queryOrderParams="{
                page: 1,
                size: 100,
                isVisible: 1,
                tournamentId: extraData.tournamentId,
                sportId: extraData.sportId
              }"
              :value.sync="extraData.regionTeamId"
              search-key="name"
              label-key="name"
              value-key="id"
            />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >Search</el-button
        >
        <el-button @click="dialogAdd" v-if="!isPlayer">Add</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      stripe
      v-loading="loading"
      @sort-change="sort"
      :max-height="tableHeight"
      @expand-change="expandChange"
    >
      <el-table-column type="expand" width="120">
        <template slot-scope="props">
          <el-table
            class="expand-table"
            v-loading="expandData[props.row.id].loading"
            :data="expandData[props.row.id].data"
            style="width: 100%"
          >
            <el-table-column
              label="Source Data"
              width="120"
              align="center"
              prop="dataSourceValue"
            >
            </el-table-column>
            <el-table-column
              label="Source ID"
              width="120"
              align="center"
              prop="sourceTeamId"
            >
            <template v-slot="{row}">
              {{isPlayer ? row.sourcePlayerId : row.sourceTeamId}}
            </template>
            </el-table-column>
            <el-table-column
              label="Source Sport"
              width="140"
              align="center"
              prop="sourceSportName"
            ></el-table-column>
           
            <el-table-column
              label="Source Region"
              v-if="!isPlayer"
              width="160"
              align="center"
              prop="sourceRegionName"
            >
            </el-table-column>
            <el-table-column
              :label="`${isPlayer ? 'Player': 'Team'} Name`"
              width="180"
              align="center"
              prop="name"
            ></el-table-column>
             <el-table-column
              v-if="isPlayer"
              label="Source Tournament"
              width="260"
              align="center"
              prop="sourceTournamentName"
            ></el-table-column>
            <el-table-column
              label="Remove"
              width="160"
              align="center"
            >
              <template v-slot="{ row }">
                <status-icon
                  @click.native="remove(row, props.row)"
                  :status="'delete'"
                />
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        :label="`${isPlayer ? 'Player': 'Team'} ID`"
        width="140"
        align="center"
        prop="id"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        label="Sport Name"
        min-width="130"
        align="center"
        prop="sportId"
        sortable="custom"
      >
       <template v-slot="{ row }">
             {{isPlayer ? row.standardSportName : row.sportsName}}
              </template>
      </el-table-column>
      <el-table-column
      v-if="!isPlayer"
        label="region"
        align="center"
        min-width="160"
        prop="regionName"
      >
        <template v-slot="{ row, $index }">
          <div class="regionClass">
            <span>{{ row.regionName }}</span>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              circle
              v-if="$index !== editRowIndex"
              @click="handleEdit($index)"
            ></el-button>
            <input-autocomplete
              v-if="$index === editRowIndex"
              :row="{
                id: row.id,
                nameCode: row.nameCode
              }"
              :editRowIndex.sync="editRowIndex"
              :request-method="editTeamRegion"
              @get-list="getList"
              :queryOrderParams="{ page: 1, size: 500, deleteFlag: 1 }"
              search-key="STD_SEARCH_REGION"
              value-key="name"
              label-key="id"
              query-key="regionName"
              update-key="regionId"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="!isPlayer"
        :label="`${isPlayer ? 'Player': 'Team'} Name`"
        min-width="180"
        align="center"
        prop="name"
      ></el-table-column>
      <el-table-column
        label="Short Name"
        v-if="!isPlayer"
        min-width="120"
        align="center"
        prop="shortName"
      ></el-table-column>
      <el-table-column v-if="!isPlayer" label="Logo" align="center" prop="logoUrl">
        <template v-slot="{ row }">
          <img
            class="table-team-logo"
            v-if="row.logoUrl"
            :src="row.logoUrl"
            alt="team-logo"
          />
        </template>
      </el-table-column>
      <el-table-column
      v-if="!isPlayer"
        label="Coach"
        align="center"
        prop="coach"
      ></el-table-column>
      <el-table-column
      v-if="!isPlayer"
        label="Stadium"
        align="center"
        prop="stadium"
      ></el-table-column>
      <el-table-column
      v-if="!isPlayer"
        label="Introduction"
        min-width="120"
        align="center"
        prop="introduction"
      ></el-table-column>
      <el-table-column
        label="Tournament"
        align="center"
        min-width="140"
        prop="tournamentId"
        sortable="custom"
      >
       <template v-slot="{ row }">
             {{isPlayer ? row.standardTournamentName : row.tournament}}
              </template>
      </el-table-column>
             <el-table-column v-if="isPlayer" label="Logo" align="center" prop="logoUrl">
        <template v-slot="{ row }">
          <img
            class="table-team-logo"
            v-if="row.logoUrl"
            :src="row.logoUrl"
            alt="team-logo"
          />
        </template>
      </el-table-column>
       <el-table-column
        v-if="isPlayer"
        :label="`${isPlayer ? 'Player': 'Team'} Name`"
        min-width="180"
        align="center"
        prop="name"
        sortable="custom"
      ></el-table-column>
      <el-table-column v-if="isPlayer" label="Club Team" min-width="160" prop="clubTeamId" sortable="custom">
        <template v-slot="{row}">{{row.clubTeamName}}</template>
      </el-table-column>
      <el-table-column v-if="isPlayer" min-width="170" label="Club Number" prop="clubNumber" sortable="custom">
      </el-table-column>
      <el-table-column v-if="isPlayer" label="Country Team" min-width="160" prop="regionTeamId" sortable="custom">
        <template v-slot="{row}">{{row.regionTeamName}}</template>
      </el-table-column>
      <el-table-column v-if="isPlayer" min-width="170" label="Country Number" prop="regionNumber" sortable="custom">
      </el-table-column>
      <el-table-column
        label="Remark"
        align="center"
        prop="remark"
        min-width="130"
        sortable="custom"
      >
        <template v-slot="{ row }">
          <dropdown
            :row="{
              id: row.id
            }"
            :methodStr="isPlayer ? 'remarkPlayer' : methodStr"
            :text="`${row.remark || 'Please select'}`"
            @get-list="getList"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="Validity"
        min-width="100"
        align="center"
        prop="visible"
        sortable="custom"
      >
        <template v-slot="{ row }">
          <el-switch v-if="isPlayer" v-model="row.visible" :active-value="1" :inactive-value="0" @change="switchHandle(row)"></el-switch>
          <el-switch v-else v-model="row.switchStatus" :active-value="true" :inactive-value="false" @change="switchHandle(row)"></el-switch>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Repush" min-width="160" align="center">
        <template v-slot="{ row }">
           <el-button
           type="text"
            @click="changeRepushStatus(row, $event)"
          >Repush</el-button>
        </template>
      </el-table-column> -->
      <el-table-column
        label="Operation"
        min-width="100"
        align="center"
        prop="shortName"
      >
        <template v-slot="{ row }">
          <el-link type="primary" :underline="false" @click="edit(row)"
            >Edit</el-link
          >
        </template>
      </el-table-column>
        <el-table-column
        label="E-Sport"
        min-width="100"
        align="center"
        prop="shortName"
        v-if="!isPlayer"
      >
        <template v-slot="{ row }">
          <el-link v-if="[1,3].includes(row.sportId)" type="primary" :underline="false" @click="editEsport(row)"
            >Edit</el-link
          >
        </template>
      </el-table-column>
      <el-table-column label="Log" min-width="160" align="center">
        <template v-slot="{ row }">
          <OperationType :dataType="isPlayer ? 'Standard_Player' : 'Standard_Team'" :dataId="row.id" />
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end" style="margin: 20px 0 0 0">
      <el-pagination
        :total="total"
        :current-page="queryData.current"
        :page-size="queryData.size"
        :page-sizes="pageSizes"
        @current-change="changePage"
        @size-change="handleSizeChange"
        :layout="pageLayout"
      ></el-pagination>
    </el-row>
    <el-dialog
      :title="dialog.title"
      width="500px"
      :visible.sync="dialog.show"
      :close-on-click-modal="dialog.clickModal"
      :destroy-on-close="true"
    >
      <el-form
        :model="dialogForm"
        :rules="rules"
        label-width="144px"
        size="mini"
        :ref="dialog.ref"
      >
        <el-form-item :label="`${isPlayer ? 'Player': 'Team'} ID:`" v-if="dialogForm.id">
          {{ dialogForm.id }}
        </el-form-item>
        <el-form-item :label="`${isPlayer ? 'Player': 'Team'} Name:`" prop="name" required>
          <el-input
            placeholder="Please typing in team name"
            v-model="dialogForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="Short Name:" v-if="!isPlayer">
          <el-input
            placeholder="Please typing in short name"
            v-model="dialogForm.shortName"
          ></el-input>
        </el-form-item>
        <el-form-item label="Sports:" prop="sportId" required>
          <select-box
            :url="STD_SEARCH_SPORT"
            :queryOrderParams="{ page: 1, size: 500 }"
            :value.sync="dialogForm.sportId"
            :label="dialogForm.sportsName"
            search-key="name"
            label-key="sportsName"
            value-key="id"
          />
        </el-form-item>
        <el-form-item label="Tournament:" prop="tournamentId">
          <select-box
            :url="STD_SEARCH_TOURNAMENT"
            :queryOrderParams="{ page: 1, size: 100 }"
            :value.sync="dialogForm.tournamentId"
            :label="dialogForm.tournament"
            search-key="name"
            label-key="name"
            value-key="id"
          />
        </el-form-item>
        <el-form-item label="Club Team ID" v-if="isPlayer">
             <el-input
                v-model="dialogForm.clubTeamId"
                @change="inputTeam($event, 0)"
                style="width: 120px"
                placeholder="Input"
              ></el-input>
              <label class="el-form-item__label" style="width: 50px;float:none;padding-left:10px">Name</label>
              <select-box
                key="clubTeam"
                :url="STD_SEARCH_TEAM"
                ref="clubTeam"
                width="127"
                :queryOrderParams="{
                  page: 1,
                  size: 100,
                  isVisible: 1,
                  sportId: dialogForm.sportId
                }"
                :label="dialogForm.clubTeamName"
                :value.sync="dialogForm.clubTeamId"
                search-key="name"
                label-key="name"
                value-key="id"
              />
        </el-form-item>
        <el-form-item label="Club Number" v-if="isPlayer">
          <el-input v-model="dialogForm.clubNumber" placeholder="Input" type="number"></el-input>
        </el-form-item>
        <el-form-item label="Country Team ID" v-if="isPlayer">
             <el-input
                v-model="dialogForm.regionTeamId"
                @change="inputTeam($event, 1)"
                style="width: 120px"
                placeholder="Input"
              ></el-input>
            <label class="el-form-item__label" style="width: 50px;float:none;padding-left:10px">Name</label>
            <select-box
                :url="STD_SEARCH_TEAM"
                ref="countryTeam"
                width="127"
                :queryOrderParams="{
                  page: 1,
                  size: 100,
                  isVisible: 1,
                  sportId: dialogForm.sportId
                }"
                :label="dialogForm.regionTeamName"
                :value.sync="dialogForm.regionTeamId"
                search-key="name"
                label-key="name"
                value-key="id"
              />
        </el-form-item>
        <el-form-item label="Country Number" v-if="isPlayer">
          <el-input v-model="dialogForm.regionNumber" placeholder="Input" type="number"></el-input>
        </el-form-item>
        <el-form-item v-if="!isPlayer" label="Standard Region:" prop="regionId">
          <select-box
            :url="STD_SEARCH_REGION"
            :queryOrderParams="{ page: 1, size: 500, deleteFlag: 1 }"
            :value.sync="dialogForm.regionId"
            :label="dialogForm.regionName"
            search-key="name"
            label-key="name"
            value-key="id"
          />
        </el-form-item>
        <el-form-item label="Logo:">
          <logo-upload :url.sync="dialogForm.logoUrl" />
        </el-form-item>
        <el-form-item label="Coach:" v-if="!isPlayer">
          <el-input
            placeholder="Please typing in coach"
            v-model="dialogForm.coach"
          ></el-input>
        </el-form-item>
        <el-form-item label="Stadium:" v-if="!isPlayer">
          <el-input
            placeholder="Please typing in stadium"
            v-model="dialogForm.stadium"
          ></el-input>
        </el-form-item>
        <el-form-item label="Introduction:" v-if="!isPlayer">
          <el-input
            placeholder="Please typing in Introduction"
            v-model="dialogForm.introduction"
          ></el-input>
        </el-form-item>
        <el-form-item label="Remark:">
          <el-input
            placeholder="Please typing in remark"
            v-model="dialogForm.remark"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :loading="dialog.isLoading"
            @click="dialogSubmit"
            >Confirm</el-button
          >
          <el-button @click="dialogHide">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :title="dialogForm.name" :visible.sync="esportDialog">
      <el-form v-if="dialogForm.names" label-width="144px" size="mini">
        <el-form-item :label="`E-${dialogForm.sportId === 1?  'Soccer': 'Basketball'} 1`">
          <el-input v-model="dialogForm.names[0]" placeholder="Input"></el-input>
        </el-form-item>
        <el-form-item :label="`E-${dialogForm.sportId === 1?  'Soccer': 'Basketball'} 2`">
          <el-input v-model="dialogForm.names[1]" placeholder="Input"></el-input>
        </el-form-item>
        <el-form-item :label="`E-${dialogForm.sportId === 1?  'Soccer': 'Basketball'} 3`">
          <el-input v-model="dialogForm.names[2]" placeholder="Input"></el-input>
        </el-form-item>
           <el-form-item>
          <el-button
            type="primary"
            @click="saveEsport"
            >Confirm</el-button
          >
          <el-button @click="esportDialog = false">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTeamList,
  standardPlayerList,
  addTeam,
  savePlayer,
  editTeam,
  updateStandardPlayer,
  editTeamRegion,
  getThirdTeamById,
  updateThirdTeamById,
  teamValidity,
  getThirdPlayerById,
  updateVisiblePlayer,
  updateThirdPlayerById,
  getTeamList as findTeam,
  teamRePush,
  playerRePush,
  teamGet,
  teamSave
} from '@/service/standard'
import TableMixin from '@/mixins/standard/tableSelect'
import { Dropdown, InputAutocomplete, LogoUpload } from '@/components'
import { cloneDeep } from 'lodash'
import StatusIcon from '@/components/StatusIcon'
export default {
  mixins: [TableMixin],
  components: { Dropdown, InputAutocomplete, LogoUpload, StatusIcon },
  data() {
    return {
      esportDialog: false,
      methodStr: 'editTeamRemark',
      editRowIndex: null,
      extraData: {},
      expandData: {},
      rules: {
        name: [
          {
            message: 'Please typing in Team name',
            required: true,
            pattern: '[^ \x22]+'
          }
        ],
        sportId: [
          {
            message: 'Please select sports',
            required: true
          }
        ]
      }
    }
  },
  watch: {
    'extraData.id' () {
      if (this.extraData.id) {
        this.extraData.name = ''
        this.extraData.sportId = ''
        this.extraData.regionId = ''
      }
    }
  },
  computed: {
    isPlayer() {
      return this.$route.path.indexOf('player') > -1
    }
  },
  methods: {
    editEsport(row) {
      teamGet({standardTeamId: row.id}).then(res => {
        if(res.code === 0) {
          const [a, b, c] = res.data || []
          const names = [a || '', b || '', c ||'']
          this.dialogForm = {names, sportId: row.sportId, standardTeamId: row.id,name: row.name};
          this.esportDialog =  true
        }  
      })

    },
    saveEsport() {
      teamSave(this.dialogForm).then(res => {
        if(res.code === 0) {
          this.esportDialog = false
          this.getList()
        }
      })
    },
    inputTeam(e, type = 1) {
      console.log(e, type)
      if(!e) {
        return
      }
      const refName = type === 1 ? 'countryTeam' : type == 0 ? 'clubTeam' : type === 3? 'selectTournament': 'teamSelect';
      (type === 3 ? getTournamentList({id: e}) : findTeam({ id: e })).then((res) => {
        if (res.code === 0) {
          const list = res.data.records
          if(list.length === 0) {
            if(type === 1) {
              this.dialogForm.homeTeamId = ''
            }
            if(type === 0) {
              this.dialogForm.clubTeamId = ''
            }
            if(type === 3) {
              this.dialogForm.tournamentId = ''
            }
            return
          }
          const [{ name }] = list
          this.$refs[refName].optionData = list
          this.$nextTick((e) => {
            this.$refs[refName].$refs.select.selectedLabel = name
          })
        }
      })
    },
    changeRepushStatus(row) {
      const apiFn = this.isPlayer ? playerRePush : teamRePush
      apiFn(row.id).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
          }
        })
    },
    countryTeamChange(isId){
      let inputTarget = this.$refs.countryTeamId.$children[0]
      if(isId){
        inputTarget = this.$refs.countryTeam.$children[0]
      }
      inputTarget.focus()
      this.$nextTick(() => {
        inputTarget.blur()
      })
    },
    clubTeamChange(isId){
      let inputTarget = this.$refs.clubTeamId.$children[0]
      if(isId){
        inputTarget = this.$refs.clubTeam.$children[0]
      }
      inputTarget.focus()
      this.$nextTick(() => {
        inputTarget.blur()
      })
    },
     getData(...rest) {
      if (this.isPlayer) {
       return standardPlayerList(...rest)
      } else {
       return getTeamList(...rest)
      }
    },
    editData(...rest) {
      if (this.isPlayer) {
       return updateStandardPlayer(...rest)
      } else {
       return editTeam(...rest)
      }
    },
    addData(...rest) {
      if (this.isPlayer) {
       return savePlayer(...rest)
      } else {
       return addTeam(...rest)
      }
    },
    editTeamRegion,
    handleEdit(index) {
      this.editRowIndex = index
    },
    getList() {
      this.loading = true
      const extraData = {...this.fliterParams(this.extraData) || {}}
      if(extraData.clubTeamId1) {
        extraData.clubTeamId = extraData.clubTeamId1
      }
      if(extraData.regionTeamId1) {
        extraData.regionTeamId = extraData.regionTeamId1
      }
      this.getData({ ...this.queryData, ...extraData })
        .then(res => {
          const data = res.data
          if (res.code === 0) {
            this.list = data.records
            this.list = this.list.map(item => ({
              ...item,
              switchStatus: item.isVisible === 1,
            }))
            this.total = data.total
            this.loading = false
          }
        })
        .catch(err => {
          this.list = []
          this.loading = false
        })
    },
    edit(row) {
      const {clubTeamName,clubTeamId, clubNumber,regionTeamName,regionTeamId,regionNumber} = row
      const data = {
        id: row.id,
        nameCode: row.nameCode,
        remark: row.remark,
        sportId: row.sportId,
        sportsName: row.sportsName || row.standardSportName,
        regionId: row.regionId,
        regionName: row.regionName,
        tournamentId: row.tournamentId,
        tournament: row.tournament || row.standardTournamentName,
        name: row.name,
        [this.isPlayer? 'oldPlayerName' : 'oldTeamName']: row.name,
        shortName: row.shortName,
        coach: row.coach,
        stadium: row.stadium,
        logoUrl: row.logoUrl,
        introduction: row.introduction,
        clubTeamName,clubTeamId, clubNumber,regionTeamName,regionTeamId,regionNumber
      }
      this.dialogEdit(data)
    },
    expandChange(row) {
      let { id } = row
      this.upDataExpandData(id, {
        loading: true,
        data: null
      });
      (this.isPlayer ? getThirdPlayerById({standardPlayerId: id}) : getThirdTeamById({standardTeamId: id})).then(res => {
          this.upDataExpandData(id, {
            loading: false,
            data: res.data
          })
        })
        .catch(() => {
          this.upDataExpandData(id, {
            loading: false,
            data: null
          })
        })
    },
    upDataExpandData(id, data) {
      console.log(data)
      let expandData = cloneDeep(this.expandData)
      expandData[id] = data
      this.expandData = expandData
    },
    remove (row, propsRow) {
      this.$msgbox({
        title: '',
        type: 'warning',
        message: `Remove the mapping ?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(action => {
        (this.isPlayer ? updateThirdPlayerById({thirdPlayerId: row.id}) : updateThirdTeamById({thirdTeamId: row.id})).then(res => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
            this.expandChange(propsRow)
          }
        })
      })
    },
    switchHandle(row) {
      if (row.switchStatus) {
        this.$msgbox({
          title: '',
          type: 'warning',
          message: `Switch to invalid?`,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then(action => {
        (this.isPlayer? updateVisiblePlayer({playerId: row.id, visible: row.visible ? 1 : 0}) : teamValidity({teamId: row.id, validity: row.switchStatus ? 1 : 0})).then(res => {
            if (res.code === 0) {
              this.$message({
                message: 'success',
                type: 'success'
              })
            }
          })
          setTimeout(() => {
            this.getList()
          },1000)
        }).catch(action => {
          row.switchStatus = !row.switchStatus
        })
      } else {
        this.$msgbox({
          title: '',
          type: 'warning',
          message: `Switch to Valid?`,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then(action => {
         (this.isPlayer? updateVisiblePlayer({playerId: row.id, visible: row.visible ? 1 : 0}) : teamValidity({teamId: row.id, validity: row.switchStatus ? 1 : 0})).then(res => {
            if (res.code === 0) {
              this.$message({
                message: 'success',
                type: 'success'
              })
            }
          })
          setTimeout(() => {
            this.getList()
          },1000)
        }).catch(action => {
          row.switchStatus = !row.switchStatus
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table-team-logo {
  height: 32px;
  width: 32px;
}
/deep/ .el-table__expanded-cell {
  padding-left: 10px;
}
.regionClass {
  span {
    margin-right: 5px;
  }
}
</style>
